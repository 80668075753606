<template>
  <div style="color: rgb(77, 91, 124);">
    <template v-if="d_frontendList.length > 0">
      <b-row>
        <b-col sm="12" lg="1"></b-col>
        <b-col sm="12" lg="10">
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ StoreLangTranslation.data['frontend_list'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <select class="form-control" v-model="d_frontendClientID">
                <option v-for="(frd, frd_index) in d_frontendList" :value="frd.client_id">
                  {{ frd.w_id }} / {{ frd.client_id }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <select class="form-control" v-model="d_frdWebunitData.status">
                <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['0'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <select class="form-control" v-model="d_frdWebunitData.show_in_menu">
                <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['1'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['3']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <select class="form-control" v-model="d_frdWebunitData.click_type">
                <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['3'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['6']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <select class="form-control" v-model="d_frdWebunitData.is_dashboard">
                <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['6'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['2']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                <b-col sm="12" lg="4">
                  {{ lang.label }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frdWebunitData.menu_caption[lang.label]">
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['4']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                <b-col sm="12" lg="4">
                  {{ lang.label }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frdWebunitData.route_path[lang.label]">
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['5']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                <b-col sm="12" lg="4">
                  {{ lang.label }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frdWebunitData.page_top_caption[lang.label]">
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['23']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                <b-col sm="12" lg="4">
                  {{ lang.label }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frdWebunitData.website_unit_no[lang.label]">
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['24']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                <b-col sm="12" lg="4">
                  {{ lang.label }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frdWebunitData.parent_website_unit_no[lang.label]">
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['25']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                <b-col sm="12" lg="4">
                  {{ lang.label }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frdWebunitData.route_name[lang.label]">
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['30']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                <b-col sm="12" lg="4">
                  {{ lang.label }}
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="d_frdWebunitData.authorization_status">
                    <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['30'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_frdWebUnitWdm['0']['parameters']['31']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                <b-col sm="12" lg="4">
                  {{ lang.label }}
                </b-col>
                <b-col sm="12" lg="8">
                  <input type="text" class="form-control" v-model="d_frdWebunitData.authorization_code[lang.label]">
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="6">
            </b-col>
            <b-col sm="12" lg="6">
              <b-button variant="secondary" @click="f_addNewPageInfo()">
                yeni sayfa içeriği
              </b-button>
            </b-col>
          </b-row>
          <template v-if="d_frdWebunitData.page_info.length > 0">
            <template v-for="(page_info, page_info_index) in d_frdWebunitData.page_info" :style="f_calculatePageInfoStyle(page_info_index)">
              <b-row>
                <b-col sm="12" lg="12">
                  <b-row style="margin: 3px;">
                    <b-col sm="12" lg="10">
                      <strong style="cursor: pointer;" @click="f_openPageInfoIndex(page_info_index)">{{ page_info_index + 1 }} )</strong>
                    </b-col>
                    <b-col sm="12" lg="9" style="text-align: right;">
                        <b-button size="sm" style="margin-right: 5px;" variant="primary" @click="f_copyPageInfo(page_info_index)">
                          {{ StoreLangTranslation.data['copy'][StoreLang] }}
                        </b-button>
                        <b-button size="sm" style="margin-right: 5px;" variant="secondary" @click="f_listParamOrder(d_frdWebunitData.page_info, page_info_index, 'up')">
                          {{ StoreLangTranslation.data['up'][StoreLang] }}
                        </b-button>
                        <b-button size="sm" style="margin-right: 5px;" variant="secondary" @click="f_listParamOrder(d_frdWebunitData.page_info, page_info_index, 'down')">
                          {{ StoreLangTranslation.data['down'][StoreLang] }}
                        </b-button>
                        <b-button size="sm" style="margin-right: 5px;" variant="secondary" @click="f_listParamOrder(d_frdWebunitData.page_info, page_info_index, 'top')">
                          {{ StoreLangTranslation.data['top'][StoreLang] }}
                        </b-button>
                        <b-button size="sm" style="margin-right: 5px;" variant="secondary" @click="f_listParamOrder(d_frdWebunitData.page_info, page_info_index, 'bottom')">
                          {{ StoreLangTranslation.data['bottom'][StoreLang] }}
                        </b-button>
                        <b-button size="sm" variant="danger" @click="f_deletePageInfo(page_info_index)">
                          {{ StoreLangTranslation.data['delete'][StoreLang] }}
                        </b-button>
                      </b-col>
                  </b-row>
                  <b-row style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['8']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                        <b-col sm="12" lg="4">
                          {{ lang.label }}
                        </b-col>
                        <b-col sm="12" lg="8">
                          <input type="text" class="form-control" v-model="page_info.page_info_caption[lang.label]">
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px;">
                    <b-col sm="12" lg="4">
                      {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['27']['name']['translation'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="8">
                      <select class="form-control" v-model="page_info.component_type">
                        <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['7']['parameters']['27'].options" :value="op.value">
                          {{ op.translation[StoreLang] }}
                        </option>
                      </select>
                    </b-col>
                  </b-row>
                  <b-row style="margin: 3px;">
                    <b-col sm="12" lg="4">
                    </b-col>
                    <b-col sm="12" lg="8" style="text-align: right;">
                      <b-button variant="warning" @click="f_openPageInfoIndex(page_info_index)">
                        {{ StoreLangTranslation.data['details'][StoreLang] }}
                      </b-button>
                    </b-col>
                  </b-row>
                  <template v-if="d_openPageInfoIndexList.indexOf(page_info_index) !== -1">
                    <b-row style="margin: 3px;">
                      <b-col sm="12" lg="6">
                      </b-col>
                      <b-col sm="12" lg="6">
                        <b-button variant="success" @click="f_addNewPageInfoDetail(page_info_index)">
                          içerik detayı ekle
                        </b-button>
                      </b-col>
                    </b-row>
                    <template v-if="page_info.page_info_detail !== undefined && page_info.page_info_detail.length > 0">
                      <b-row v-for="(page_info_detail, page_info_detail_index) in page_info.page_info_detail" :style="f_calculatePageInfoDetailStyle(page_info_detail_index)">
                        <b-col sm="12" lg="12">
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="3">
                              <strong>{{ page_info_index + 1 }} . {{ page_info_detail_index + 1 }}</strong>
                            </b-col>
                            <b-col sm="12" lg="9" style="text-align: right;">
                              <b-button size="sm" style="margin-right: 5px;" variant="primary" @click="f_copyPageInfoDetail(page_info_index, page_info_detail_index)">
                                {{ StoreLangTranslation.data['copy'][StoreLang] }}
                              </b-button>
                              <b-button size="sm" style="margin-right: 5px;" variant="secondary" @click="f_listParamOrder(page_info.page_info_detail, page_info_detail_index, 'up')">
                                {{ StoreLangTranslation.data['up'][StoreLang] }}
                              </b-button>
                              <b-button size="sm" style="margin-right: 5px;" variant="secondary" @click="f_listParamOrder(page_info.page_info_detail, page_info_detail_index, 'down')">
                                {{ StoreLangTranslation.data['down'][StoreLang] }}
                              </b-button>
                              <b-button size="sm" style="margin-right: 5px;" variant="secondary" @click="f_listParamOrder(page_info.page_info_detail, page_info_detail_index, 'top')">
                                {{ StoreLangTranslation.data['top'][StoreLang] }}
                              </b-button>
                              <b-button size="sm" style="margin-right: 5px;" variant="secondary" @click="f_listParamOrder(page_info.page_info_detail, page_info_detail_index, 'bottom')">
                                {{ StoreLangTranslation.data['bottom'][StoreLang] }}
                              </b-button>
                              <b-button size="sm" style="margin-right: 5px;" variant="danger" @click="f_deletePageInfoDetail(page_info_index, page_info_detail_index)">
                                {{ StoreLangTranslation.data['delete'][StoreLang] }}
                              </b-button>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['10']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.page_info_detail_caption[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['26']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <b-textarea v-model="page_info_detail.page_info_detail_note[lang.label]" rows="10"></b-textarea>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['11']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.button_name[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['12']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <b-textarea v-model="page_info_detail.code[lang.label]" rows="10"></b-textarea>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['13']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.link[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['14']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.method_name[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['15']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.trigger_point[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['16']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.image_name[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['17']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.mail[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['18']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.iframe[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['19']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.img_link[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['28']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;">
                                <b-col sm="12" lg="4">
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <select class="form-control" v-model="page_info_detail.wanalyzer_project_usage_type">
                                    <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['28'].options" :value="op.value">
                                      {{ op.translation[StoreLang] }}
                                    </option>
                                  </select>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['20']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.machine_learning_id[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['21']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.wanalyzer_project_id[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['32']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <input type="text" class="form-control" v-model="page_info_detail.wanalyzer_stats_id[lang.label]">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;" v-if="page_info_detail.wanalyzer_project_usage_type === '0'">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['29']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;">
                                <b-col sm="12" lg="4">
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <select class="form-control" v-model="page_info_detail.getting_analysis_output_with_column_inputs_id_type">
                                    <option v-for="(op, op_index) in d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['29'].options" :value="op.value">
                                      {{ op.translation[StoreLang] }}
                                    </option>
                                  </select>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="4">
                              {{ d_frdWebUnitWdm['0']['parameters']['7']['parameters']['9']['parameters']['22']['name']['translation'][StoreLang] }}
                            </b-col>
                            <b-col sm="12" lg="8">
                              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                                <b-col sm="12" lg="4">
                                  {{ lang.label }}
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <b-form-file
                                      @input="f_addImageToPageInfoDetailAsBase64(page_info_index, page_info_detail_index, lang.label)"
                                      style="height: 100px; border: dotted 2px #25bcff; background: #daf9ff;"
                                      v-model="d_tmpFile"
                                      :accept="f_createAcceptedDocumentTypes()"
                                      placeholder="Choose a file or drop it here..."
                                      drop-placeholder="Drop file here..."
                                  >
                                    <img src="@/icon/1530793.png" style="width: 2em;">
                                  </b-form-file>
                                  <b-button v-if="page_info_detail.image_base64[lang.label]" @click="$delete(page_info_detail.image_base64, lang.label)" variant="danger">sil</b-button>
                                  <img v-if="page_info_detail.image_base64[lang.label]" :src="page_info_detail.image_base64[lang.label]" style="height: 100px;">
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                </b-col>
              </b-row>
            </template>
          </template>
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="6">
            </b-col>
            <b-col sm="12" lg="6">
              <b-button variant="secondary" @click="f_addNewFrontendWebsiteUnit()">
                {{ StoreLangTranslation.data['save'][StoreLang] }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" lg="1"></b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import FrontendService from '@/services/frontend';
import { data as frd_webunit } from '@/options/frd_webunit';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'systemvariable',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_tmpFile:  null,
      d_openPageInfoIndexList: [],
      d_frontendClientID: '',
      d_frontendWebsiteClientID: '',
      d_frontendList: [],
      d_frdWebUnitWdm: frd_webunit,
      d_frdWebunitData: {
        'click_type': '0',
        'is_dashboard': '0',
        'menu_caption': {},
        'page_top_caption': {},
        'parent_website_unit_no': {},
        'route_name': {},
        'route_path': {},
        'show_in_menu': '1',
        'status': '0',
        'website_unit_no': {},
        'authorization_status': '0',
        'authorization_code': {},
        'page_info': [],
      }
    };
  },
  created: function () {
    let lang_eligible_variables = ['menu_caption', 'page_top_caption', 'parent_website_unit_no', 'route_name', 'route_path', 'website_unit_no'];
    for (let k in lang_eligible_variables) {
      for (let lang_index in this.StoreLangList.data) {
        this.d_frdWebunitData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
      }
    }
    this.f_frontendList();
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreLangList);
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_frdWebUnitWdm);
  },
  methods: {
    f_addImageToPageInfoDetailAsBase64: function (page_info_index, page_info_detail_index, lang_label) {
      if (this.d_tmpFile) {
        // console.log(this.d_tmpFile);
        let file_name = this.d_tmpFile.name;
        let arr = this.d_tmpFile.name.split('.');
        let fileExtension = arr[arr.length - 1];
        if (["jpg", "jpeg", "png"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_extension_type_is_not_eligible'][this.StoreLang]);
          return;
        }
        if (this.d_tmpFile.size > 10485760) {
          this.d_tmpFile = null;
          this.$forceUpdate();
          alert(this.StoreLangTranslation.data['file_size_is_not_eligible'][this.StoreLang], ' : ', this.d_tmpFile.size);
          return;
        }
        var reader = new FileReader();
        var vm = this;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          // console.log(reader.result);
          // console.log('page_info_index ', page_info_index);
          // console.log('page_info_detail_index ', page_info_detail_index);
          // console.log('vm.d_frdWebunitData.page_info ', vm.d_frdWebunitData.page_info);
          vm.d_frdWebunitData.page_info[page_info_index].page_info_detail[page_info_detail_index].image_base64[lang_label] = reader.result;
          vm.$forceUpdate();
        }, false);
        reader.readAsDataURL(this.d_tmpFile);
        this.d_tmpFile = null;
        this.$forceUpdate();
      }
    },
    f_createAcceptedDocumentTypes: function () {
      let accept = '';
      let file_types = ["jpg", "jpeg", "png"];
      for (let i in file_types) {
        accept += '.' + file_types[i] + ',';
      }
      return accept;
    },
    f_openPageInfoIndex: function (page_info_index) {
      if (this.d_openPageInfoIndexList.indexOf(page_info_index) === -1) {
        this.d_openPageInfoIndexList.push(page_info_index);
      } else {
        this.d_openPageInfoIndexList.splice(this.d_openPageInfoIndexList.indexOf(page_info_index), 1);
      }
      this.$forceUpdate();
    },
    f_deletePageInfo: function (page_info_index) {
      this.d_frdWebunitData.page_info.splice(page_info_index, 1);
    },
    f_deletePageInfoDetail: function (page_info_index, page_info_detail_index) {
      this.d_frdWebunitData.page_info[page_info_index].page_info_detail.splice(page_info_detail_index, 1);
    },
    f_copyPageInfoDetail: function (page_info_index, page_info_detail_index) {
      let copied = JSON.parse(JSON.stringify(this.d_frdWebunitData.page_info[page_info_index].page_info_detail[page_info_detail_index]));
      this.d_frdWebunitData.page_info[page_info_index].page_info_detail.push(copied);
    },
    f_copyPageInfo: function (page_info_index) {
      let copied = JSON.parse(JSON.stringify(this.d_frdWebunitData.page_info[page_info_index]));
      this.d_frdWebunitData.page_info.push(copied);
    },
    f_listParamOrder: function (list, index, direction) {
      // console.log('list:', list, 'index:', index, 'direction:', direction);
      let index_data = list[index];
      let count = list.length;
      let friend = '';
      if (direction === 'up') {
        if (index !== 0) {
          friend = list[index - 1];
          list[index - 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'down') {
        if (index !== count - 1) {
          friend = list[index + 1];
          list[index + 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'top') {
        list.splice(index, 1);
        list.splice(0, 0, index_data);
      } else if (direction === 'bottom') {
        list.splice(index, 1);
        list.push(index_data);
      }
      this.$forceUpdate();
    },
    f_orderPageInfoDetail: function (page_info_index, page_info_detail_index, order_type) {
      if (order_type === 'down') {
        if (this.d_frdWebunitData.page_info[page_info_index].page_info_detail.length === 1) {
          //
        } else {
          if (page_info_detail_index < this.d_frdWebunitData.page_info[page_info_index].page_info_detail.length - 1) {
            //
          }
        }
      }
    },
    f_calculatePageInfoStyle: function (page_info_index) {
      let style = 'margin: 3px;';
      if (page_info_index % 2 === 0) {
        style += 'background-color: #99e4e8';
      } else {
        style += 'background-color: #99e4e8';
        // style += 'background-color: white;';
      }
      return style;
    },
    f_calculatePageInfoDetailStyle: function (page_info_detail_index) {
      let style = 'margin: 3px;';
      if (page_info_detail_index % 2 === 0) {
        style += 'background-color: #bcf1f4;';
      } else {
        style += 'background-color: white;';
      }
      return style;
    },
    f_addNewPageInfo: function () {
      let lang_eligible_variables = ['page_info_caption'];
      let new_page_info_item = { 'page_info_caption': {}, 'component_type': '', 'page_info_detail': [] };
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          new_page_info_item[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
        }
      }
      this.d_frdWebunitData.page_info.push(new_page_info_item);
    },
    f_addNewPageInfoDetail: function (page_info_index) {
      let lang_eligible_variables = [
        'page_info_detail_caption',
        'page_info_detail_note',
        'button_name',
        'code',
        'link',
        'method_name',
        'trigger_point',
        'image_name',
        'mail',
        'iframe',
        'img_link',
        'machine_learning_id',
        'wanalyzer_project_id',
        'wanalyzer_stats_id',
        'image_base64',
        'wanalyzer_project_usage_type',
      ];

      let new_page_info_detail_item = {
        'page_info_detail_caption': {},
        'page_info_detail_note': {},
        'button_name': {},
        'code': {},
        'link': {},
        'method_name': {},
        'trigger_point': {},
        'image_name': {},
        'mail': {},
        'iframe': {},
        'img_link': {},
        'machine_learning_id': {},
        'wanalyzer_project_id': {},
        'wanalyzer_stats_id': {},
        'image_base64': {},
        'wanalyzer_project_usage_type': {},
      };
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          new_page_info_detail_item[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
        }
      }
      if (this.d_frdWebunitData.page_info[page_info_index].page_info_detail === undefined) {
        this.d_frdWebunitData.page_info[page_info_index].page_info_detail = [];
      }
      this.d_frdWebunitData.page_info[page_info_index].page_info_detail.push(new_page_info_detail_item);
    },
    f_addNewFrontendWebsiteUnit: function () {
      let query = 'frontend_client_id=' + this.d_frontendClientID;
      if (this.d_frontendWebsiteClientID) {
        query += '&websiteunit_client_id=' + this.d_frontendWebsiteClientID;
      }
      let data = { 'websiteunit': this.d_frdWebunitData };
      FrontendService.frontend_website_unit_new(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_frontendList: function () {
      let query = '';
      let data = {};
      FrontendService.frontend_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_frontendList = resp.data.list;
              if (this.$route.query.frontend_client_id) {
                this.d_frontendClientID = this.$route.query.frontend_client_id;
              }
              if (this.$route.query.websiteunit_client_id) {
                this.d_frontendWebsiteClientID = this.$route.query.websiteunit_client_id;
                this.f_frontendWebsiteunitGet();
              }
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_frontendWebsiteunitGet: function () {
      let query = 'websiteunit_client_id=' + this.d_frontendWebsiteClientID;
      query += '&data_type=value_mode';
      let data = {};
      FrontendService.frontend_websiteunit_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_frdWebunitData = resp.data.data;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
  }
};

</script>

